import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = ({ data, location, pageContext}) => {
  return <Layout location={location} title="About Social Dance Today" backTo="Back To Regions" backToLink={"/"} noEmailForm={true}>
      <Seo
        title={"About Social Dance Today"}
        description={"Up-to-date social dance listings. Find socials in your area to dance salsa, bachata, zouk, and more."}
      />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
      <header>
        <h1 itemProp="headline">About</h1>
      </header>

      <p>The mission of Social Dance Today is to provide accurate information about dance events and classes to the dance community.</p>

      <p>To submit feedback about the site or submit new event listings or corrections, please email <a href="mailto:support@crewcraftsmedia.com">support@crewcraftsmedia.com</a>.</p>
    </article>
  </Layout>
}

export default AboutPage

export const aboutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
